<template>
    <div class="booth-ad-content">
        <el-table :data="hotKeywordList" border style="width: 100%; margin-top: 10px; flex: 1" size="medium"
                  @sort-change="sortChange"
                  :header-cell-style="{fontWeight: 'normal', height: '48px', color: '#666666', background: '#F5F5F5'}"
                  :cell-style="{fontSize: '14px',color: '#343434', height: '60px'}">
            <el-table-column>
                <template slot="header" slot-scope="scope">
                    <div class="search-keywords">
                        关键词
                        <el-input v-model="searchValue" @change="searchBtn" clearable size="small" placeholder="输入关键词搜索"/>
                    </div>
                </template>
                <template slot-scope="scope">
                    <span>{{ scope.row.keyword }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="search_num" align="center" sortable="custom" label="搜索量"></el-table-column>
            <el-table-column prop="avg_price" align="center" sortable="custom" label="平均出价"></el-table-column>
            <el-table-column prop="click_rate" align="center" sortable="custom" label="点击率">
                <template slot-scope="scope">
                    {{scope.row.click_rate}}%
                </template>
            </el-table-column>
            <el-table-column prop="tran_rate" align="center" sortable="custom" label="转化率">
                <template slot-scope="scope">
                    {{scope.row.tran_rate}}%
                </template>
            </el-table-column>
        </el-table>
        <el-pagination class="operation-pagination" style="margin: 20px 0;"
                       :current-page="listPages.currentPageNum"
                       :page-size="listPages.eachPageNum"
                       :total="listPages.total"
                       layout="prev, pager, next, jumper"
                       @current-change="handleCurrentChange">
        </el-pagination>
    </div>
</template>

<script>
    export default {
        name: "HotKeyWord",
        data() {
            return {
                searchValue: '',
                //热门关键词表格数据
                hotKeywordList: [],
                //分页
                listPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 1,
                },
                order_name: void 0,
                order_type: void 0,
            }
        },
        mounted() {
            this.getHotKeywordsList()
        },
        methods: {
            // 获取热门关键词列表
            getHotKeywordsList() {
                let params = {
                    page: this.listPages.currentPageNum,
                    limit: this.listPages.eachPageNum,
                }
                if (this.order_name) {
                    params.order_name = this.order_name
                }
                if (this.order_type) {
                    params.order_type = this.order_type
                }
                if (this.searchValue) {
                    params.name = this.searchValue
                }
                this.$http.axiosGetBy(this.$api.hotKeyWordAnalysis, params, (res) => {
                    if (res.code === 200) {
                        this.hotKeywordList = res.data.list
                        this.listPages.total = res.data.total
                    } else {
                        this.$message.warning(res.msg)
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            sortChange(column, prop, order) {
                this.order_name = column.prop
                if (column.order === 'ascending') {
                    this.order_type = 'asc'
                } else if (column.order === 'descending') {
                    this.order_type = 'desc'
                } else {
                    this.order_type = void 0
                }
                this.getHotKeywordsList()
            },
            handleCurrentChange(val) {
                this.listPages.currentPageNum = val
                this.getHotKeywordsList()
            },
            // 搜索关键词
            searchBtn() {
                this.listPages.currentPageNum = 1
                this.getHotKeywordsList()
            }
        }
    }
</script>

<style scoped lang="scss">
    .booth-ad-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: 0 20px;
        min-height: calc(100vh - 267px);
    }
    .search-keywords {
        display: flex;
        align-items: center;
        .el-input {
            flex: 1;
            width: 1%;
            margin-left: 10px;
        }
    }
</style>